const FIELDS = {
  'location': {
    name: 'Location',
    type: 'string',
    width: '*',
  },
  'date': {
    name: 'Date',
    type: 'string',
    width: '*',
  },
  'drug_type': {
    name: 'Drug Type',
    type: 'string',
    width: '*',
  },
  'user': {
    name: 'User',
    type: 'string',
    width: '*',
  },
  'discrepancy_count': {
    name: 'Discrepancies',
    type: 'string',
    width: '*',
  },
};

export default class StockCheckListController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $state, Session, DrugStockResource, DrugStockCheckResource, CreateDrugStockCheckDialog, StockLocationResource
  ) {
    this._state = $state;
    this._Session = Session;
    this._DrugStockResource = DrugStockResource;
    this._DrugStockCheckResource = DrugStockCheckResource;
    this._CreateDrugStockCheckDialog = CreateDrugStockCheckDialog;
    this._StockLocationResource = StockLocationResource;
  }

  $onInit () {
    const userGroup = this._Session.user().group.slug;
    this.userCanCreateStockCheck = ~['system-administrators', 'ops-management', 'medical-staff'].indexOf(userGroup);

    this.gridColumns = Object.assign({}, FIELDS);
    this.gridActionCol = {
      position: 'end',
      options: { width: '64' },
      actions: [{
        name: 'View',
        callback: this.viewStockCheck.bind(this),
      }],
    };
    this.gridOptions = {
      enablePagination: true,
      useExternalPagination: true,
      totalItems: 0,
      resolvePage: (pageNumber, pageSize) => {
        this.loadData(pageNumber, pageSize);
      },
    };

    this.stockLocations = [];
    this._StockLocationResource.index().then(items => { this.stockLocations.push(...items); });
    this.loadData();
  }

  showCreateDialog ($event) {
    this._CreateDrugStockCheckDialog.show({
      $event,
      locals: { stockLocations: this.stockLocations },
    }).then(data => {
      this._state.go('admin.drugs.stockChecks.create', data);
    });
  }

  viewStockCheck (stockCheck) {
    this._state.go('admin.drugs.stockChecks.show', { id: stockCheck.id });
  }

  loadData (pageNumber, pageSize) {
    if (!pageNumber) { pageNumber = 1; }
    if (!pageSize) { pageSize = 25; }

    this._DrugStockCheckResource
      .index({ include: 'stockLocation,user', page: pageNumber, page_size: pageSize })
      .then(data => {
        this.gridOptions.pageNumber = pageNumber;
        this.gridOptions.totalItems = data.pagination.total;
        this.items = data.data.map(this._formatItem);
      });
  }

  _formatItem (item) {
    return Object.assign(item, {
      location: item.stock_location.name,
      date: item.date.format('DD/MM/YYYY'),
      drug_type: item.controlled ? 'Controlled' : 'Non-controlled',
      user: `${item.user.first_name} ${item.user.last_name}`,
    });
  }
}
