export default {
  bindings: {
    crewMember: '<',
    onEditButtonClick: '&',
  },
  controller: function (Session) {
    'ngInject';

    this.hasPermissionsToEdit = function (crewMember) {
      const user = Session.user();
      return ['system-administrators', 'ops-management'].indexOf(user.group.slug) > -1;
    };
  },
  templateUrl: 'pages/crewMembers/templates/crewMemberProfile.tpl.html',
};
