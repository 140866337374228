import RestResource from 'common/resource/services/restResource';

export function format (item) {
  let formatted = {
    id: item.id,
    name: item.name,
  };

  if (item.incidentSubtypes) {
    formatted.subtypes = item.incidentSubtypes.data;
  }

  return formatted;
}

export default class IncidentTypeResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/incident-types';
  }

  format (item) {
    return format(item);
  }
};
