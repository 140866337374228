import moment from 'moment';
import { copy } from 'angular';
import { DATE_FORMAT } from 'common/resource/module.constants';

export class DeploymentPageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $window, $state, $stateParams, DeploymentPageService, PrfPageService,
    DeploymentDialog, PatientDetailsDialog, DeploymentListDialog,
    DeploymentStatusUpdateDialog, Session, ShiftService, Toast
  ) {
    this.Math = $window.Math;

    this._state = $state;
    this._stateParams = $stateParams;
    this._DeploymentPageService = DeploymentPageService;
    this._PrfPageService = PrfPageService;
    this._DeploymentDialog = DeploymentDialog;
    this._PatientDetailsDialog = PatientDetailsDialog;
    this._DeploymentStatusUpdateDialog = DeploymentStatusUpdateDialog;
    this._DeploymentListDialog = DeploymentListDialog;
    this._Session = Session;
    this._ShiftService = ShiftService;
    this._Toast = Toast;
  }

  $onInit () {
    this._deploymentRelationData = { shifts: [] };
    this._prfRelationData = {};

    this._DeploymentPageService.getDeployment(this._stateParams.id).then(deployment => {
      this.deployment = this._formatDeployment(deployment);
      this._generateTimelineAndDeploymentTimes();
      this._generateBreadcrumbs();
      if (this.hasPermissionToEditDeployment()) {
        this._loadShiftCrewData(this.deployment.date.format(DATE_FORMAT));
      }
    });

    if (this.hasPermissionToEditDeployment()) {
      this._loadRelationData();
    }
  }

  // Duplicate code is acceptable in hasPermissionToViewPRFs and hasPermissionToAddPRFs.
  // It can differ between ambulance services, and therefore should be left as separate methods.
  hasPermissionToViewPRFs () {
    const user = this._Session.user();
    if (!user.group || !user.group) return false;
    return ['system-administrators', 'ops-management', 'medical-staff'].indexOf(user.group.slug) > -1;
  }

  hasPermissionToAddPRFs () {
    const user = this._Session.user();
    if (!user.group || !user.group) return false;
    return ['system-administrators', 'medical-staff'].indexOf(user.group.slug) > -1;
  }

  hasPermissionToEditDeployment () {
    const user = this._Session.user();
    if (!user.group || !user.group) return false;
    return ['charity-staff'].indexOf(user.group.slug) === -1;
  }

  orderCrewMembers (item) {
    switch (item.type) {
      case 'pilot': return 1;
      case 'medic': return 2;
      case 'observer': return item.id ? 3 : 4;
    }
  }

  showDeploymentDialog ($event) {
    if (!this.deployment) {
      return;
    }

    const dialogParams = {
      $event: $event,
      item: copy(this.deployment),
      relationData: this._deploymentRelationData,
      locals: {
        incident: this.deployment.incident,
      },
    };

    this._DeploymentDialog.show(dialogParams).then(deployment => {
      this._Toast.showSimple('Deployment updated.');
      this.deployment = this._formatDeployment(deployment);
    }).catch(deploymentId => {
      if (typeof deploymentId !== 'string' || !deploymentId) {
        return;
      }
      this._Toast.showSimple('Deployment deleted.');
      this._state.go('incident', { id: this.deployment.incident.id });
    });
  }

  showStatusUpdateDialog ($event, item) {
    if (!this.deployment) {
      return;
    }

    const isEditing = !!item;
    if (isEditing) {
      item.time = moment(item.time).toDate();
    }

    let deployment = copy(this.deployment);
    deployment.name = deployment.vehicle_callsign.name;

    const dialogParams = {
      $event: $event,
      item: item,
      relationData: this._deploymentRelationData,
      locals: {
        deployment: copy(deployment),
      },
    };

    this._DeploymentStatusUpdateDialog.show(dialogParams).then(status => {
      if (isEditing) {
        this.deployment.deployment_statuses = this.deployment.deployment_statuses.map(
          item => item.id === status.id ? Object.assign({}, item, status) : item
        );
      } else {
        this.deployment.deployment_statuses = [...this.deployment.deployment_statuses, status];
      }
      this.deployment.outcome = this._DeploymentPageService.getDeploymentOutcomes(this.deployment);
      this._generateTimelineAndDeploymentTimes();
      this._Toast.showSimple('Deployment status updated.');
    }).catch(statusId => {
      if (!statusId) {
        return;
      }
      this.deployment.deployment_statuses = this.deployment.deployment_statuses.filter(({ id }) => id !== statusId);
      this.deployment.outcome = this._DeploymentPageService.getDeploymentOutcomes(this.deployment);
      this._generateTimelineAndDeploymentTimes();
      this._Toast.showSimple('Deployment status deleted.');
    });
  }

  showDeploymentStatusList ($event) {
    if (!this.deployment) {
      return;
    }

    const dialogParams = {
      $event: $event,
      item: copy(this.deployment.deployment_statuses),
      relationData: this._deploymentRelationData,
      locals: {
        incident: this.deployment.incident,
        deploymentStatuses: this.deployment.deployment_statuses,
        showEditDialog: this.showStatusUpdateDialog.bind(this),
      },
    };

    this._DeploymentListDialog.show(dialogParams);
  }

  showPRFDialog (evt) {
    if (!this.deployment) {
      return;
    }

    if (!Object.keys(this._prfRelationData).length) {
      // TODO: Replace with patient types repo (body parts and drugs are irrelevant here)
      this._PrfPageService.getRelationData().then(relationData => {
        Object.assign(this._prfRelationData, relationData);
      });
    }

    const dialogParams = {
      $event: evt,
      locals: {
        deployment: this.deployment,
        incident: this.deployment.incident,
        relationData: this._prfRelationData,
      },
    };

    return this._PatientDetailsDialog.show(dialogParams).then(item => {
      this._state.go('prf.patientDetails', { id: item.id });
    });
  }

  _formatDeployment (deployment) {
    return {
      ...deployment,
      outcome: this._DeploymentPageService.getDeploymentOutcomes(deployment),
      crew_members: deployment.crew_members.map(i => {
        if (i.type !== 'observer' || i.type === 'observer' && i.id != null) return i;
        return { ...i, first_name: 'OTHER', profession: { title: 'Observer' } };
      }),
    };
  }

  _loadShiftCrewData (date) {
    const include = 'properties.vehicleCallsign,users.profile.image,users.profile.profession';
    this._ShiftService.getShiftsForDate(date, include).then(shifts => {
      this._deploymentRelationData.shifts = [...this._deploymentRelationData.shifts, ...shifts];
    });
    this._ShiftService.getOvernightShiftsForDate(date).then(data => {
      const overnightShifts = data.map(shift => ({ ...shift, name: `${shift.name} (previous day)` }));
      this._deploymentRelationData.shifts = [...this._deploymentRelationData.shifts, ...overnightShifts];
    });
  }

  _loadRelationData () {
    this._DeploymentPageService.getRelationData().then(relationData => {
      Object.assign(this._deploymentRelationData, relationData);
    });

    this._DeploymentPageService.getDestinationData().then(relationData => {
      Object.assign(this._deploymentRelationData, { destinations: relationData });
    });
  }

  _generateTimelineAndDeploymentTimes () {
    this.timelineEvents = this._DeploymentPageService.generateTimeline(this.deployment.deployment_statuses);
    if (this.deployment.deployment_statuses) {
      this.mobileTime = this._DeploymentPageService.calculateMobileTime(this.deployment.deployment_statuses);
      this.responseTime = this._DeploymentPageService.calculateResponseTime(
        this.deployment.incident.times.call.time,
        this.deployment.deployment_statuses
      );
    }

    this.deployment.patient_report_forms.forEach(PRF => {
      let transportedTo = this._PrfPageService.getTransportedDestination(this.deployment.deployment_statuses);
      PRF.outcome = this._PrfPageService.getPRFOutcome(transportedTo);
      PRF.surveys.forEach((survey, i) => {
        let timelineEvent = {
          label: `${PRF.first_name} ${PRF.last_name} - Survey ${i + 1}`,
          time: moment(survey.time),
        };
        this.timelineEvents.push(timelineEvent);
      });
    });
  }

  _generateBreadcrumbs () {
    this.routes = [
      {
        title: 'Request Log',
        state: 'requestLog()',
      },
      {
        title: 'Incident',
        state: `incident({id: '${this.deployment.incident.id}'})`,
      },
      {
        title: 'Deployment',
      },
    ];
  }
}

export default {
  controller: DeploymentPageController,
  templateUrl: 'pages/requestLog/components/deployment-page/deployment-page.tpl.html',
};
