class HandoverNotesTabController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (HospitalInformationDialog, PrfPageService, Toast) {
    this.HospitalInformationDialog = HospitalInformationDialog;
    this.PrfPageService = PrfPageService;
    this.Toast = Toast;
  }

  $onInit () {
    if (this.prf && this.prf.deployment) {
      this.transportedTo = this.PrfPageService.getTransportedDestination(this.prf.deployment.deployment_statuses);
    }
  }

  $onChanges (changes) {
    if (changes.prf && changes.prf.currentValue && changes.prf.currentValue.deployment) {
      const deployment = changes.prf.currentValue.deployment;
      this.transportedTo = this.PrfPageService.getTransportedDestination(deployment.deployment_statuses);
    }
  }

  showHandoverNotesDialog ($event, handoverNotes) {
    this.HospitalInformationDialog
      .show({
        editing: true,
        $event,
        item: handoverNotes,
        locals: { prfId: this.prf.id },
      })
      .then(handoverNotes => {
        this.prf.handover_notes = handoverNotes;
        this.Toast.showSimple('Handover notes updated.');

        if (typeof this.onPrfUpdate === 'function') {
          this.onPrfUpdate({ prf: this.prf });
        }
      });
  }
}

export default {
  controller: HandoverNotesTabController,
  templateUrl: 'pages/requestLog/components/handover-notes-tab/handover-notes-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    onPrfUpdate: '&',
  },
};
