import { copy } from 'angular';
import moment from 'moment';
import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

const generateIncident = function (date, location) {
  return {
    date: date,
    status: 'accepted',
    times: {
      emergency: { date: date },
      call: { date: date },
      accepted: { date: date },
    },
    location: {
      location: location || null,
    },
  };
};

const formatIncident = function (item) {
  return {
    ...item,
    date: moment(item.date).toDate(),
    times: Object.keys(item.times).reduce((acc, cur) => {
      const datetime = moment(item.times[cur].time).toDate();
      return { ...acc, [cur]: { ...item.times[cur], time: datetime, date: datetime } };
    }, {}),
  };
};

export default class IncidentDialogController extends ResourceDialogController {
  init () {
    super.init();
    this._timeout = this.$injector.get('$timeout');
    this._scope = this.$injector.get('$rootScope').$new();
    this._resource = this.$injector.get('IncidentResource');
    this._Location = this.$injector.get('Location');
    this.APP_ORG_SHORT = this.$injector.get('APP_ORG_SHORT');
    this._INCIDENT_ID = uuid.v4();

    this.timeLabelMap = {
      accepted: 'Task Accepted',
      declined: 'Task Declined',
      emergency: '999 Call Connect',
      call: 'Time to ' + this.APP_ORG_SHORT,
    };

    this.today = moment().startOf('minute').toDate();

    this.item = this.item
      ? formatIncident(this.item)
      : generateIncident(this.today, this.location);

    this._scope.$watchGroup(
      [
        () => this.item.location ? this.item.location.location : null,
        () => this.item.times && this.item.times.call ? this.item.times.call.time : null,
        () => this.item.date ? this.item.date : null,
      ],
      this._updateIncidentValues.bind(this)
    );
  }

  submit (formData) {
    if (!this.form) {
      throw new Error('Form not found.');
    }

    if (this.form.$invalid) {
      this.Toast.showSimple(this.validationErrorMessage);
      return;
    }

    if (!this.item.location || !this.item.location.lng || !this.item.location.lat) {
      this.Dialog.alert('Incident location incorrect.', 'Error');
      return;
    }

    if (this.editing) {
      this.update(formData);
    } else {
      this.create(formData);
    }
  };

  create (formData) {
    const data = this._formatIncidentForRequest(formData, this.timeLabelMap);
    data.id = this._INCIDENT_ID;
    this._resource.create(data).then(() => {
      this.Dialog.hide(data);
    }).catch(console.log.bind(console));
  };

  update (formData) {
    const data = this._formatIncidentForRequest(formData, this.timeLabelMap);
    this._resource.update(data.id, data, {
      include: [
        'incidentType', 'incidentSubtype', 'serviceArea', 'refusalReason',
        'ambulanceService', 'deploymentReason', 'deployments.vehicleCallsign', 'deployments.patientReportForms',
        'deployments.deploymentStatuses.deploymentStatus', 'deployments.deploymentStatuses.destination',
      ],
    }).then(incident => {
      this.Dialog.hide(incident);
    }).catch(console.log.bind(console));
  }

  delete (id) {
    this.Dialog.promptDelete().then(() => {
      this._resource.destroy(id)
        .then(() => {
          this.Dialog.cancel(id);
        })
        .catch(err => {
          let msg = 'Error deleting incident.';
          if (err.status && err.status === 409) {
            msg = err.data.message;
          }
          this.Toast.showSimple(msg);
        });
    });
  }

  _updateIncidentValues () {
    let timeToOrg = 'HHmm';
    if (this.item.times && this.item.times.call) {
      timeToOrg = moment(this.item.times.call.time).format('HHmm');
    }

    let locationString = 'LOC';
    let location = { ...this.item.location };
    if (this.item.location && this.item.location.location) {
      locationString = this.item.location.location.toUpperCase().replace(/\s/, '');

      this._Location
        .resolveCoordinates(locationString)
        .then(coords => {
          location.lat = coords.lat;
          location.lng = coords.lng;
        })
        .catch(() => {
          location.lat = null;
          location.lng = null;
        });
    }

    this.item = {
      ...this.item,
      incident_number: moment(this.item.date).format('YYYYMMDD') + '-' + timeToOrg + '-' + locationString,
      location: location,
    };
  }

  _formatIncidentForRequest (incident, timeLabelMap) {
    const item = copy(incident);

    item.date = moment(item.date);

    item.times = Object.keys(item.times).reduce((acc, cur) => {
      if (item.times[cur].time) {
        const d = moment(item.times[cur].date);
        acc[cur] = { time: moment(item.times[cur].time).year(d.year()).month(d.month()).date(d.date()) };
      } else {
        acc[cur] = { time: null };
      }
      acc[cur].label = timeLabelMap[cur];
      return acc;
    }, {});

    return item;
  };
}
